<script lang="ts" setup>
import { Employee } from '../../components/EmployeeSingle.vue'

const props = defineProps<{
  title: string
  persons: Employee[]
  hideImages?: boolean
}>()

const personsList = computed(() =>
  props.persons.filter((person, index, self) => index === self.findIndex((t) => t.name === person.name)),
)
</script>

<template>
  <SectionSpecial :title="title">
    <ul class="cul persons">
      <li v-for="(person, index) in personsList" :key="index">
        <ToccoContactPersonTeaser :person="person" :hide-images="hideImages" />
      </li>
    </ul>
  </SectionSpecial>
</template>
<style lang="scss" scoped>
.persons {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--gap);
  @include breakpoint(t) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include breakpoint(ds) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
